import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

const StyledContent = styled.div`
   p,
   span,
   li {
      ${tw`text-gray-800`}
   }
   a {
      ${tw`text-primary-600`}
   }
`;

const Page = ({ data }) => {
   return (
      <Layout>
         <SearchEngineOptimization
            title="Marshall Fires Legal Support | JBP Legal"
            description="JBP is extending its legal services to those impacted by the recent Marshall Fires. To learn more, please visit us."
         />
         <div className="container">
            <article className="pt-12 md:pt-20 pb-20 md:pb-32 bg-white px-6 md:px-14 lg:px-22">
               <header className="mb-10 md:mb-12 md:text-center">
                  <h1 className="text-gray-800 heading-two">You Are Our Community & We Support You</h1>
               </header>

               <div className="mb-12 md:mb-14">
                  <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} />
               </div>

               <StyledContent>
                  <p>JBP is extending its services to those impacted by the recent Marshall Fires.</p>

                  <p>
                     If you’re a homeowner in need of information, we’re happy to review your circumstances and advise on matters relating to
                     insurance processes and reconstruction—at no cost.
                  </p>

                  <p>
                     <b>
                        Please contact us today at <a href="tel:303-678-0560">303-678-0560</a>.
                     </b>
                  </p>

                  <p>
                     To support the communities impacted by the Marshall Fires, please consider donating to the{" "}
                     <a href="https://www.commfound.org/wildfirefund" target="_blank" rel="noreferrer">
                        Boulder County Wildfire Fund
                     </a>
                     .
                  </p>
               </StyledContent>
            </article>
         </div>

         <CallToAction />
      </Layout>
   );
};

export const data = graphql`
   {
      hero: file(relativePath: { eq: "LP/jon-cartagena-WabUXjporxY-unsplash (1).jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1440)
         }
      }
   }
`;

export default Page;
